/* CSS for PDF Viewer */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .licensing-container {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 80vh;
            min-width: 50vw;
            overflow: auto;
            /* height: '100%'; */
            /* max-height: 100vh; */
            
}


.licensing-wrapper {
    background-color: #f0f0f0; 
           margin: 0;
            padding: 20px;
            max-width: 600px;
            min-width: 500px;
            /* display: flex; */
            /* align-items: center; */
            /* justify-content: center; */
}

.licensing-viewer {
    /* zoom: 5; */
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* min-height: 50vh; */
    max-height: 800px;
    max-width: 600px;
    min-width: 500px;
  }
  
  .licensing-title {
    background-color: #f0f0f0;
    margin-top: -10px;
    padding-left: 10px;
    padding-right: 10px;
}

  
  .paper {
    background-color: white;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* border: 1px solid #ccc; */
    width: 100%;
    height: auto;
    text-align: center; /* Change 'center' to 'right' or 'left' as needed */
  }
  
  .pdf-actions {
    padding: 20px;
    width: 90%;
    text-align: center; /* Change 'center' to 'right' or 'left' as needed */
  }
  
  .content {
    /* Define the content area within the paper */
    font-family: "Times New Roman", Times, serif;
  line-height: 1.5;
  font-size: 14pt;
color:black;
  }
  
  /* Styling for PDF content (titles, paragraphs, etc.) */
  .content h1 {
    font-family: "Times New Roman", Times, serif;
    font-size: 14pt;
    color:black;
    line-height: 10px;

  }
  
  .content p {
    font-family: "Times New Roman", Times, serif;
    margin-bottom: 10px;
    text-align: center;
    font-size: 18pt;
    color:black;

  }
  
  .content .pdf-label {
    font-family: "Times New Roman", Times, serif;
    margin-bottom: 10px;
    font-size: 12pt;
    font-weight: bold;
    color:black;

  }
  
  .content .pdf-text {
    font-family: "Times New Roman", Times, serif;
    margin-bottom: 10px;
    font-size: 12pt;
    color:black;

    
  }
  
  .text-underlined {
    text-decoration: underline;
  }
  
  .text-bold {
    font-weight: bold;
  }
  
  .pdf-hr {
    border: none;
    height: 1px;
    background: black;
    margin-bottom: 10px;
    margin-top: 10px;
    /* Set the hr color */
  }
  
  
  .d-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  
  .font-500 {
    font-weight: 500;
  }
  
  .font-600 {
    font-weight: 600;
  }
  
  .text-11 {
    font-size: 11px;
  }
  
  
  /* Define page breaks for printing (optional) */
  @media print {
    .paper {
      /* page-break-after: always; */
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 50px;
      margin-right: 50px;
      
      
    }
    
    .content .pdf-label {
        font-family: "Times New Roman", Times, serif;
        margin-bottom: 10px;
        font-size: 18pt;
        font-weight: bold;
        color:black;
    
      }
      
      
      .content .pdf-text {
        font-family: "Times New Roman", Times, serif;
        margin-bottom: 10px;
        font-size: 18pt;
        color:black;
    
        
      }
      
      .content h1 {
        font-family: "Times New Roman", Times, serif;
        font-size: 20pt;
        color:black;
    
      }
      
    
  }